<template>
  <div class="create-purchase-order">
    <!-- 標題 -->
    <div class="single-page-tag">
      <p class="sp-start sp-text fw-bolder">建立叫貨單
        <a href="https://youtu.be/qTPwuMNq1Og?si=aTj_n0ofFjYllMKo" target="_blank">
          <img
            class="info-icon"
            src="@/assets/icon/interrogation.png"
          />
        </a>
      </p>
    </div>
    <!-- 內容 -->
    <div class="tw-container shadow border border-1 border-dark p-0 p-2 mt-3">
      <div
        class="mb-4 row"
        style="height: calc(100vh - 200px); overflow-y: scroll"
      >
        <!-- 左(填寫表單) -->
        <div class="col-lg p-0 pe-1">
          <div class="d-flex justify-content-between my-3">
            <p class="tw-border-start fw-bolder tw-text-size20">叫貨單資訊</p>
            <!-- 商品訂單備註按鈕 -->
            <i
              class="bi bi-chat-right-dots text-danger pointer can-click"
              title="訂單備註"
              @click="showModal('merchandiseOrderHasNote')"
              v-if="Object.keys(merchandiseOrderHasNoteDatas).length > 0"
            ></i>
          </div>
          <p class="alert-success mb-2 p-3 text-center fw-bolder">
            {{ stockList.name }}
          </p>
          <!-- 選擇供應商 -->
          <select
            class="form-select mb-2"
            name="stockSuppliers"
            v-model="purchaseOrder.supplierId"
          >
            <option value="不選擇" disabled selected>不選擇供應商</option>
            <template
              v-for="supplier in stockList.suppliers"
              :key="supplier.id"
            >
              <option :value="supplier.supplierId">
                {{ supplier.name }}
              </option>
            </template>
          </select>
          <!-- 叫貨數量 -->
          <div class="form-floating mb-2">
            <input
              onwheel="this.blur()"
              type="number"
              min="0"
              class="form-control"
              placeholder="叫貨數量"
              :value="purchaseOrder.orderGoods"
              disabled
            />
            <label for="">叫貨數量</label>
          </div>
          <!-- 運費 -->
          <div class="form-floating mb-2">
            <input
              onwheel="this.blur()"
              type="number"
              min="0"
              class="form-control"
              placeholder="運費"
              v-model="purchaseOrder.shippingCost"
              @input="
                purchaseOrder.shippingCost = $methods.numberToFixed(
                  purchaseOrder.shippingCost
                )
              "
            />
            <label for="">運費:</label>
          </div>
          <!-- 金額調整 -->
          <div class="form-floating mb-2">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              placeholder="金額調整"
              v-model="purchaseOrder.adjustment"
              @change="
                purchaseOrder.adjustment = $methods.numberToFixed(
                  purchaseOrder.adjustment
                )
              "
            />
            <label for="">金額調整:</label>
          </div>
          <!-- 總金額 -->
          <div class="form-floating mb-2">
            <input
              onwheel="this.blur()"
              type="number"
              min="0"
              class="form-control"
              placeholder="總金額"
              :value="purchaseOrder.total"
              disabled
            />
            <label for="">總金額:</label>
          </div>
          <!-- 應付金額 -->
          <div class="form-floating mb-2">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              placeholder="應付金額"
              :value="purchaseOrder.payable"
              disabled
            />
            <label for="">應付金額:</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              rows="3"
              id="floatingTextarea2"
              style="height: 200px"
              maxlength="300"
              v-model="purchaseOrder.note"
            ></textarea>
            <label for="floatingTextarea2">備註:(300字)</label>
          </div>
        </div>
        <!-- 右(表格) -->
        <div class="col-lg p-0 ps-1">
          <!-- 搜尋 -->
          <div class="input-group my-2">
            <input
              type="text"
              class="form-control d-inline-block"
              placeholder="輸入欲搜尋款式"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              v-model="search"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              @click="merchandiseStyleSearch"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              class="tw-btn"
              :class="{
                'tw-btn-success': searchStatus === 'all',
                'tw-btn-danger': searchStatus === 'outOfStock',
              }"
              @click="changeSearchStatus"
            >
              <span v-if="searchStatus === 'all'">全部</span>
              <span v-else>需訂</span>
            </button>
          </div>
          <!-- table -->
          <div
            class="table-responsive"
            style="height: calc(100vh - 245px); overflow-y: scroll"
          >
            <table class="table" width="50%" style="">
              <thead class="float-header">
                <tr>
                  <th scope="col">主要款式</th>
                  <th scope="col">成本</th>
                  <th scope="col">待叫貨數</th>
                  <th scope="col">叫貨數量</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="item in merchandiseStylesStocksSearch"
                  :key="item.id"
                >
                  <tr
                    v-if="
                      (searchStatus === 'outOfStock' &&
                        item.totalShortageQuantity > 0) ||
                      searchStatus === 'all'
                    "
                  >
                    <td>{{ item.name.join() }}</td>
                    <td>
                      <input
                        onwheel="this.blur()"
                        type="number"
                        min="0"
                        class="form-control d-inline-block"
                        placeholder=""
                        v-model="item.cost"
                        pattern="[0-9]*"
                        @input="item.cost = $methods.numberToFixed(item.cost)"
                      />
                    </td>
                    <td>{{ item.totalShortageQuantity }}</td>
                    <td>
                      <input
                        onwheel="this.blur()"
                        type="number"
                        min="0"
                        class="form-control d-inline-block"
                        placeholder=""
                        v-model="item.stockQty"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 按鈕 -->
      <div class="text-end">
        <router-link
          :to="`/seller/store/${storeId}/merchandise`"
          class="tw-btn tw-btn-secondary me-2"
          >返回</router-link
        >
        <button class="tw-btn tw-btn-success" @click="createPurchaseOrder">
          建立叫貨單
        </button>
      </div>
    </div>
  </div>
  <!-- 建立叫貨單_商品備註的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="merchandiseOrderHasNoteModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">訂單備註
            <a href="https://www.youtube.com/watch?v=OUQpuVpmKw0" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 內容 -->
          <div
            style="background: #d4e2d4"
            class="border rounded-3 p-2 m-0 mb-2"
            v-for="(merchandise, index) in merchandiseOrderHasNoteDatas"
            :key="index"
          >
            <!-- 商品資訊 -->
            <div class="row">
              <div class="col-3 p-0">
                <Image
                  class="table-img"
                  :imageHash="merchandise[0].frontImage"
                  size="s"
                  v-if="merchandise[0].frontImage"
                  :alt="merchandise[0].frontImage"
                  style="width: 50px; height: 50px"
                ></Image>
                <img
                  class="table-img"
                  v-else
                  src="@/assets/other-images/noImg.png"
                  alt=""
                />
              </div>
              <div class="col-9 p-1 fw-bolder">
                <p class="mb-2">商品名稱：</p>
                <router-link
                  :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${merchandise[0].merchandiseId}`"
                  class="text-primary"
                  target="_blank"
                >
                  {{ merchandise[0].merchandiseName }}
                </router-link>
              </div>
            </div>
            <hr class="mt-2" />
            <!-- 訂單備註 -->
            <div
              class="row px-1 py-2 border mb-2"
              style="background: #faf3f0"
              v-for="merchorder in merchandise"
            >
              <div class="col-5 col-sm-3 d-flex">
                <ul>
                  <!-- 顧客名稱 -->
                  <li class="fw-bolder">
                    顧客：
                    <router-link
                      :to="`/seller/store/${storeId}/participant/${merchorder.buyerId}/merchOrder`"
                      class="text-primary"
                      target="_blank"
                      >{{ merchorder.buyerName }}</router-link
                    >
                  </li>
                  <!-- 顧客 Line 名稱 -->
                  <li class="mt-1" v-if="merchorder.buyerLineName">
                    <img
                      class="icon"
                      title="顧客的 Line 名稱"
                      src="@/assets/icon/line-icon.png"
                      alt=""
                    />
                    <span>&ensp;{{ merchorder.buyerLineName }}</span>
                  </li>
                  <!-- 顧客 FB 名稱 -->
                  <li class="mt-1" v-if="merchorder.buyerFbName">
                    <img
                      class="icon"
                      src="@/assets/icon/facebook-2.jpg"
                      title="顧客的 Facebook 名稱"
                      alt=""
                    />
                    <span>&ensp;{{ merchorder.buyerFbName }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-7 col-sm-9 border-start">
                <!-- 訂單編號 & 款式 -->
                <div class="row mb-2">
                  <!-- 訂單編號 -->
                  <div class="col p-0" title="訂單編號">
                    <img class="icon" src="@/assets/icon/bookmark.png" alt="" />
                    ：
                    <router-link
                      class="fw-bolder"
                      :to="`/seller/store/${storeId}/merchOrder?search=${merchorder.merchOrderId}`"
                      target="_blank"
                    >
                      {{ merchorder.merchOrderId }}
                    </router-link>
                  </div>
                  <!-- 款式 -->
                  <div class="col">
                    <span class="fw-bolder">款式：</span>
                    {{ merchorder.merchandiseStyleName }}
                  </div>
                </div>
                <!-- 備註 -->
                <div>
                  <span class="fw-bolder">備註：</span>
                  <span style="font-style: italic">{{
                    merchorder.merchOrderNote
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // modal
      merchandiseOrderHasNoteModal: {},
      // data
      serverToken: '',
      storeId: 0,
      merchandiseId: 0,
      stockList: {},
      merchandiseStylesStocksSearch: [],
      search: '',
      searchStatus: 'outOfStock', // outOfStock: 需訂，all: 全部
      // 建立叫貨單
      merchandiseOrderHasNoteDatas: {}, // 存放訂單備註
      purchaseOrder: {
        orderGoods: 0,
        total: 0,
        payable: 0,
        adjustment: 0,
        note: '',
        shippingCost: 0,
        storeId: '',
        supplierId: '不選擇',
      }
    }
  },
  created() {
    this.initialization()
    this.getMerchandiseStylesStockInfo()
    // 取得商品未完成訂單的資料 (為了渲染備註)
    this.getMerchandiseOrderData() 
  },
  mounted() {
    this.createModals([
      'merchandiseOrderHasNoteModal'
    ])
  },
  watch: {
    merchandiseStylesStocksSearch: {
      handler(val, oldVal) {
        this.purchaseOrder.orderGoods = 0
        this.purchaseOrder.total = 0
        val.forEach(item => {
          const stockQty = item.stockQty ? item.stockQty : 0
          if (stockQty > 0) {
            this.purchaseOrder.orderGoods += stockQty
            this.purchaseOrder.total += stockQty * item.cost
          }
        })
        this.purchaseOrder.total = this.$methods.numberToFixed(this.purchaseOrder.total)
      },
      deep: true
    },
    purchaseOrder: {
      handler(val, oldVal) {
        const shippingCost = val.shippingCost ? this.$methods.numberToFixed(val.shippingCost) : 0
        const adjustment = val.adjustment ? this.$methods.numberToFixed(val.adjustment) : 0
        val.payable = this.$methods.numberToFixed(val.total + shippingCost + adjustment)
      },
      deep: true,
    },
    stockList: {
      handler(val, oldVal) {
        if ( val.suppliers.length === 1) this.purchaseOrder.supplierId = val.suppliers[0].supplierId
      }
    }
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'merchandiseOrderHasNote') {
        // 叫貨時，提供商品的訂單備註資料
        this.merchandiseOrderHasNoteModal.show()
      }
    },
    // 取得商品款式庫存資訊
    getMerchandiseStylesStockInfo() {
      const vm = this
      this.$methods.switchLoading('show')
      const getMerchandiseStylesStockInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const token = this.serverToken
      const header = {
        authorization: token,
      }
      this.merchandiseId = this.$route.query.id ? this.$route.query.id : null
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{generateMerchandiseStylesStockJsonNode,getMerchandiseSupplierLinks{getSupplier}}'
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseStylesStockInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const stockList = res.data[0].objects
            vm.stockMap(stockList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理庫存列表
    stockMap(stockList) {
      stockList.forEach(item => {
        const object = {
          arrivalTime: item.arrivalTime,
          checkOut: item.checkOut,
          createTime: item.createTime,
          deadLineTime: item.deadLineTime,
          deleted: item.deleted,
          description: item.description,
          id: item.id,
          label: item.label,
          merchandiseStylesStocks: [],
          merchandiseStylesStockJsonNode: item.merchandiseStylesStockJsonNode,
          name: item.name,
          note: item.note,
          number: item.number,
          ready: item.ready,
          sellState: item.sellStat,
          startSaleQuantity: item.startSaleQuantity,
          storageLocation: item.storageLocation,
          storeId: item.storeId,
          storeVipId: item.storeVipId,
          suppliers: {}
        }
        // 需特別處理
        // 供應商
        let supplierArray = []
        item.merchandiseSupplierLinks.forEach(supplierItem => {
          const supplierObject = {
            cost: supplierItem.cost,
            id: supplierItem.id,
            merchandiseId: supplierItem.merchandiseId,
            preset: supplierItem.preset,
            name: supplierItem.supplier.name,
            supplierId: supplierItem.supplierId
          }
          supplierArray.push(supplierObject)
        })
        object.suppliers = supplierArray
        // 商品款式
        item.merchandiseStylesStockJsonNode.forEach(item => {
          const merchandiseStylesStocks = {
            id: new Date() * 1,
            totalShortageQuantity: item.stock ? item.stock.totalShortageQuantity : 0,
            cost:  item.styles[0].cost,
            stockQty: item.stock ? item.stock.totalShortageQuantity : 0,
            merchandiseStyleIds: [],
            name: []
          }
          item.styles.forEach(style => {
            merchandiseStylesStocks.merchandiseStyleIds.push(style.id)
            merchandiseStylesStocks.name.push(style.name)
          })
          object.merchandiseStylesStocks.push(merchandiseStylesStocks)
        })
        this.stockList = JSON.parse(JSON.stringify(object))
      })
      this.merchandiseStylesStocksSearch = this.stockList.merchandiseStylesStocks
      console.log(this.stockList)
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
    },
    // 建立叫貨單
    createPurchaseOrder() {
      const vm = this
      this.$methods.switchLoading('show')
      const createPurchaseOrderApi = `${process.env.VUE_APP_API}/purchaseOrder/create`
      const token = this.serverToken
      const header = {
        authorization: token,
      }
      if (this.purchaseOrder.supplierId === '不選擇') {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請選擇供應商')
      }
      const orderHeader = {
        storeId: this.storeId,
        supplierId: this.purchaseOrder.supplierId,
        shippingCost: this.purchaseOrder.shippingCost,
        adjustment: this.purchaseOrder.adjustment,
        note: this.purchaseOrder.note
      }
      const bodies = []
      this.stockList.merchandiseStylesStocks.forEach(item => {
        if (item.stockQty > 0) {
          const obj = {
            merchandiseStyleIds: item.merchandiseStyleIds,
            cost: item.cost,
            quantity: item.stockQty
          }
          bodies.push(obj)
        }
      })
      const data = [{
        header: orderHeader,
        bodies: bodies
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: createPurchaseOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.goBack()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 搜尋款式
    merchandiseStyleSearch() {
      this.merchandiseStylesStocksSearch = 
      this.stockList.merchandiseStylesStocks.filter(item => {
        return item.name.join().match(this.search)
      })
    },
    // 切換搜尋狀態
    changeSearchStatus() {
      this.searchStatus = this.searchStatus === 'all' ? 'outOfStock' : 'all'
    },
    // 建立叫貨單模組_取得商品未完成訂單的資料 (為了渲染備註)
    getMerchandiseOrderData() {
      if (this.$route.query.id) {
        this.$methods.switchLoading('show')
        const vm = this
        let searchData = {
          storeId: this.storeId,
          orderStatus: "unfinished",
          merchandiseIds: [this.$route.query.id],
        }
        this.$api.merchOrder.getDataTable(searchData).then(res => {
          console.log(res);
          if (res.code === '200') {
            const merchorders = res.data
            console.log(merchorders)
            vm.merchandiseOrderHasNoteDatas={}
            // 依照商品分類
            merchorders.forEach((item) => {
              if(item.merchOrderNote) {
                if(vm.merchandiseOrderHasNoteDatas[item.merchandiseId]) {
                  vm.merchandiseOrderHasNoteDatas[item.merchandiseId].push(item)
                } else {
                  vm.merchandiseOrderHasNoteDatas[item.merchandiseId] = [item]
                }
              }
            })
            this.$methods.switchLoading('hide')
          }
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      }
    },
    goBack() {
      this.$router.push(`/seller/store/${this.storeId}/merchandise`)
    },
  }
}
</script>